import { useEffect } from 'react';

const GA_TRACKING_ID = 'G-BSPZ9609MG';
const CLARITY_TRACKING_ID = 'p42ru6l7pq';

declare global {
    interface Window {
        dataLayer: any[];
        gtag: (...args: any[]) => void;
    }
}

/**
 * Analytics component to integrate Google Clarity and GA4 scripts.
 * Loads necessary analytics scripts asynchronously.
 */
const Analytics = () => {
    useEffect(() => {
        // Google Analytics (GA4) Initialization
        const gaScript = document.createElement('script');
        gaScript.async = true;
        gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`;
        document.head.appendChild(gaScript);

        window.dataLayer = window.dataLayer || [];
        function gtag(...args: any[]) {
            window.dataLayer.push(args);
        }
        window.gtag = gtag;
        gtag('js', new Date());
        gtag('config', GA_TRACKING_ID);

        // Microsoft Clarity Initialization
        const clarityScript = document.createElement('script');
        clarityScript.type = 'text/javascript';
        clarityScript.async = true;
        clarityScript.innerHTML = `
      (function(c,l,a,r,i,t,y){
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", "${CLARITY_TRACKING_ID}");
    `;
        document.head.appendChild(clarityScript);
    }, []);

    return null;
};

export default Analytics;