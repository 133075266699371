import React, { Component, ReactNode } from 'react';
import { useNotification } from '@/layers/presentation/context/NotificationContext';
import { NotificationType } from '@/types/modelEnums';

interface NotificationBoundaryProps {
    children: ReactNode;
    onNotification?: (error: Error, errorInfo: React.ErrorInfo) => void;
}

interface NotificationBoundaryState {
    hasError: boolean;
}

class NotificationBoundaryWithoutHook extends Component<NotificationBoundaryProps, NotificationBoundaryState> {
    constructor(props: NotificationBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        // Access the notification handling context
        if (this.props.onNotification) {
            this.props.onNotification(error, errorInfo);
        }
    }

    render() {
        if (this.state.hasError) {
            // Render fallback UI
            return <h1>Something went wrong.</h1>;
        }

        return this.props.children;
    }
}

interface NotificationBoundaryWithHookProps extends NotificationBoundaryProps {
}

const NotificationBoundary: React.FC<NotificationBoundaryWithHookProps> = ({ children }) => {
    const { handleNotification } = useNotification();

    return (
        <NotificationBoundaryWithoutHook onNotification={(error, errorInfo) => handleNotification(NotificationType.Error, error.message, { errorInfo })}>
            {children}
        </NotificationBoundaryWithoutHook>
    );
};

export default NotificationBoundary;