import React, { createContext, useContext, ReactNode } from 'react';
import { ToastContainer, toast, ToastOptions } from 'react-toastify';
import logger from '@/layers/support/monitoring/logger';
import { NotificationType } from '@/types/modelEnums';
interface NotificationContextProps {
    handleNotification: (
        type: NotificationType,
        message: string,
        context?: Record<string, any>,
        options?: ToastOptions
    ) => void;
}

const NotificationContext = createContext<NotificationContextProps | undefined>(undefined);

export const NotificationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const handleNotification = (
        type: NotificationType,
        message: string,
        context: Record<string, any> = {},
        options?: ToastOptions
    ) => {
        // Log the notification with context
        logger.info('Global Notification Handler', { type, message, ...context });

        // Determine default options if not provided
        const toastOptions: ToastOptions = {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored",
            ...options,
        };

        // Display standard errors
        if (context.error) {
            if (context.error.name === 'ValidationError') {
                toast.warn(context.error.message || 'A validation error occurred.', toastOptions);
            } else if (context.error.name === 'AuthenticationError') {
                toast.error(context.error.message || 'Authentication failed.', toastOptions);
            } else {
                toast.error(context.error.message || 'An unexpected error occurred.', toastOptions);
            }

        // Display custom notifications
        } else {
            switch (type) {
                case NotificationType.Info:
                    toast.info(message || 'Information.', toastOptions);
                    break;
                case NotificationType.Warning:
                    toast.warn(message || 'Warning.', toastOptions);
                    break;
                case NotificationType.Error:
                    toast.error(message || 'An error occurred.', toastOptions);
                    break;
                case NotificationType.Success:
                    toast.success(message || 'Success!', toastOptions);
                    break;
                default:
                    toast(message, toastOptions);
            }
        }
    };

    return (
        <NotificationContext.Provider value={{ handleNotification }}>
            {children}
            <ToastContainer />
        </NotificationContext.Provider>
    );
};

export const useNotification = (): NotificationContextProps => {
    const context = useContext(NotificationContext);
    if (!context) {
        throw new Error('useNotification must be used within a NotificationProvider');
    }
    return context;
};