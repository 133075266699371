'use client';

import { NextUIProvider } from '@nextui-org/react'

import { AuthProvider } from '@/layers/presentation/context/AuthContext';
import { GlobalStateProvider } from '@/layers/presentation/context/GlobalStateContext';
import { AmplifyProvider } from '@/layers/presentation/providers/AmplifyProvider';
import { IntercomProvider } from '@/layers/presentation/context/IntercomContext';
import { NotificationProvider } from '@/layers/presentation/context/NotificationContext';
import NotificationBoundary from '@/layers/presentation/components/utils/NotificationBoundary';
import { I18nProvider } from '@/layers/presentation/i18n/provider';
import Analytics from '../components/utils/Analytics';

export function Providers({ children }: { children: React.ReactNode }) {
  return (    
    <I18nProvider lng="en">
      <GlobalStateProvider>
        <AmplifyProvider>
          <AuthProvider>
            <NextUIProvider>
                <NotificationProvider><NotificationBoundary>
                  <IntercomProvider>
                    <Analytics />
                    {children}
                  </IntercomProvider>
                </NotificationBoundary></NotificationProvider>
              </NextUIProvider>
          </AuthProvider>
        </AmplifyProvider>
      </GlobalStateProvider>
    </I18nProvider>
  )
}