// src/layers/presentation/i18n/config.client.ts

import { createInstance, i18n as I18nInstance } from 'i18next';
import { initReactI18next } from 'react-i18next';
import { i18nConfig } from './config.common';

/**
 * Initializes the i18n instance with translations preloaded for client-side usage.
 */
export const initializeI18n = (): I18nInstance => {
    const i18nInstance = createInstance();

    i18nInstance
        .use(initReactI18next)
        .init({
            ...i18nConfig,
            react: {
                useSuspense: false, // Disable suspense for SSR compatibility
            },
        });

    return i18nInstance;
};