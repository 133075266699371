'use client';

import { motion } from 'framer-motion';
import { usePathname } from 'next/navigation';
import { useState, useEffect } from 'react';

const FadeEffect = () => {
    const pathname = usePathname();
    const [isAnimating, setIsAnimating] = useState(false);

    useEffect(() => {
        setIsAnimating(true);
        const timer = setTimeout(() => setIsAnimating(false), 300); // Match this with your transition duration
        return () => clearTimeout(timer);
    }, [pathname]);

    return (
        <motion.div
            className="fixed inset-0 z-50 pointer-events-none"
            animate={{
                opacity: isAnimating ? 1 : 0,
                backgroundColor: isAnimating ? 'var(--nextui-colors-default500)' : 'transparent',
            }}
            transition={{ duration: 0.3 }}
        />
    );
};

export default FadeEffect;
