// src/layers/presentation/i18n/config.common.ts

import { Resource } from 'i18next';

// Import all namespace translations
import common from './locales/en/common.json';
import about from './locales/en/about.json';
import components from './locales/en/components.json';
import doctor from './locales/en/doctor.json';
import sponsor from './locales/en/sponsor.json';
import patient from './locales/en/patient.json';
import users from './locales/en/users.json';
import blog from './locales/en/blog.json';

/**
 * Shared i18n resources and configurations.
 */
export const resources: Resource = {
    en: {
        common,
        about,
        components,
        doctor,
        sponsor,
        patient,
        users,
        blog
    },
};

export const defaultNS = 'common';

export const supportedLngs = ['en'];

export const fallbackLng = 'en';

export const namespaces = [
    'common',
    'about',
    'components',
    'doctor',
    'sponsor',
    'patient',
    'users',
    'blog'
];

export const i18nConfig = {
    resources,
    supportedLngs,
    fallbackLng,
    lng: fallbackLng,
    defaultNS,
    ns: namespaces,
};