'use client';

import React, { useMemo } from 'react';
import { I18nextProvider } from 'react-i18next';
import { initializeI18n } from './config.client';
import { i18n as I18nInstance } from 'i18next';

interface I18nProviderProps {
    lng: string;
    children: React.ReactNode;
}

/**
 * I18nProvider initializes the i18n instance and provides it to the app.
 */
export const I18nProvider: React.FC<I18nProviderProps> = ({ lng, children }) => {
    const i18nInstance: I18nInstance = useMemo(() => {
        const instance = initializeI18n();
        instance.changeLanguage(lng);
        return instance;
    }, [lng]);

    return (
        <I18nextProvider i18n={i18nInstance}>
            {children}
        </I18nextProvider>
    );
};